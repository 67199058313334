<template>
  <div class="container" :style="containerWidth">
    <div class="large">
      <Video class="large-video" :vimeoId="this.selectedId" :isFullScreen="false"></Video>
      <div class="large-video-description">
        {{ $t(`accordion_video_description_${selectedId}`) }}
      </div>
    </div>
    <div v-if="videoIds.length > 1" class="small">
      <div class="thumbnail-card" v-for="id in getVideoIdExceptFocused" :key="id" @click="selectVideo(id)">
        <Video class="thumbnail" :vimeoId="id" :isFullScreen="false"></Video>
        <div>{{ $t(`accordion_video_description_${id}`) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Video from "./VideoComponent.vue";
export default {
  name: "AccordionVideoContainer",
  components: { Video },
  props: {
    videoIds: Array,
  },
  data() {
    return {
      selectedId: this.videoIds[0],
    };
  },
  computed: {
    getVideoIdExceptFocused: function () {
      return this.videoIds.filter((element) => element !== this.selectedId);
    },
    containerWidth() {
      return this.videoIds.length == 1 ? "width: auto;" : "";
    },
  },
  methods: {
    selectVideo(id) {
      this.selectedId = id;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
  gap: 30px;
  margin-bottom: 60px;

  @include breakpoint(s) {
    flex-direction: column;
  }

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.large {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include breakpoint(xs) {
    align-items: center;
  }
}

.small {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 10px;
  align-items: flex-start;
}

.thumbnail-card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 10px;
}

.thumbnail {
  height: 4vw;
  width: calc(4vw * 1.667);
  min-width: calc(4vw * 1.667);
  pointer-events: none;

  @include breakpoint(s) {
    height: 10vw;
    width: calc(10vw * 1.667);
    min-width: calc(10vw * 1.667);
  }

  @include breakpoint(xs) {
    height: 20vw;
    width: calc(20vw * 1.667);
    min-width: calc(20vw * 1.667);
  }
}

.large-video {
  height: 30vw;
  width: calc(30vw * 1.778);
  margin-bottom: 10px;

  @include breakpoint(s) {
    height: 40vw;
    width: calc(40vw * 1.778);
  }

  @include breakpoint(xs) {
    height: 45vw;
    width: calc(45vw * 1.778);
  }
}

.large-video-description {
  @include breakpoint(xs) {
    text-align: center;
    max-width: 90%;
  }
}</style>
