/* eslint-disable no-console */

import { register } from "register-service-worker";

export function startWorker(callback) {
  function sendWorkerResponse(type, details) {
    if (details) {
      console.log("[Service Worker]", type, details);
    } else {
      console.log("[Service Worker]", type);
    }
    if (callback) callback({ type, details });
  }
  register("/service-worker.js", {
    ready() {
      sendWorkerResponse("ready");
    },
    registered() {
      sendWorkerResponse("registered");
    },
    cached() {
      sendWorkerResponse("cached");
    },
    updatefound() {
      sendWorkerResponse("updatefound");
    },
    updated() {
      sendWorkerResponse("updated");
    },
    offline() {
      sendWorkerResponse("offline");
    },
    error(error) {
      sendWorkerResponse("error", error);
    },
  });
}
