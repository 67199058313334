import {
  detect
} from "detect-browser";

const { VUE_APP_IGNORE_HTTPS } = process.env;

const browser = detect();
export const isIe = browser.name === "ie";
export const isEdge = browser.name === "edge";
export const isIeOrEdge = isIe || isEdge;
export const isNeitherIeNorEdge = !isIe && !isEdge;
export const isHttps = VUE_APP_IGNORE_HTTPS === "true" || (window || document).location.protocol === "https:";
export const isPwaInstalled = checkIfApplicatinoIsInstalled();

function checkIfApplicatinoIsInstalled () {
  try {
    return window.matchMedia('(display-mode: standalone)').matches;
  }
  catch (e) {
    return false;
  }
}
