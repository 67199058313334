import { i18n } from "@/locales/i18n";

export function errorHandler(codeMap = {}) {
  return function (error) {
    if (error.response) {
      const { status } = error.response;
      if (codeMap[status]) {
        throw new Error(i18n.t(codeMap[status]));
      }
    } else {
      throw error;
    }
  };
}

export function successHandler() {
  return function (result) {
    if (Array.isArray(result.data)) {
      return result.data;
    }
    const transformed = {};
    Object.keys(result.data).forEach(key => {
      transformed[key.replace(new RegExp("-", "g"), "")] = result.data[key];
    });
    return transformed;
  };
}
