<template>
  <div id="app">
    <nav>
      <NavigationBar />
      <DocumentTitle />
      <CacheControl v-if="isPwaModeActive" />
      <SettingsModal v-if="isSettingsModalVisible" />
    </nav>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import NavigationBar from "@/components/Navigation/NavigationIndex";
import DocumentTitle from "@/components/u/DocumentTitle";
import CacheControl from "@/components/u/CacheControl";
import SettingsModal from "@/components/Settings/Modal";
export default {
  components: {
    NavigationBar,
    DocumentTitle,
    CacheControl,
    SettingsModal,
  },
  beforeCreate() {
    this.$store.commit("setLanguage", this.$i18n.locale);
  },
  mounted() {
    if (!this.isPwaModeActive) {
      this.$store.commit("serviceWorkerReady", false);
    }
  },
  watch: {
    $route(to) {
      this.$store.commit("setLanguage", to.params.lang);
      this.$i18n.locale = to.params.lang;
    },
  },
  computed: {
    isPwaModeActive() {
      return this.$store.state.isPwaModeActive;
    },
    isSettingsModalVisible() {
      return this.$store.state.isSettingsModalVisible;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";
@import "@/assets/scss/base";
</style>
