<template>
  <section id="contact" class="contact">
    <div class="contact-text">
      <h2 class="contact-text-headline">
        <i18n-t keypath="contact_header" scope="global"></i18n-t>
      </h2>
      <p class="contact-text-subheadline">
        <i18n-t keypath="contact_paragraph" scope="global"></i18n-t>
      </p>
    </div>
    <form id="contact-form" class="contact-form" @submit.prevent="processForm" role="form">
      <div class="messages"></div>
      <div class="contact-form-controls">
        <div class="contact-form-controls-row">
          <div class="form-group">
            <label for="form_name">{{ $t("contact_name") }}</label>
            <input id="form_name" type="text" name="name" class="form-control"
              :placeholder="$t('contact_placeholder_name')" required="required" v-model="name" />
            <div class="help-block with-errors"></div>
          </div>
          <div class="form-group">
            <label for="form_company">{{ $t("contact_company") }}</label>
            <input id="form_company" type="text" name="company" class="form-control"
              :placeholder="$t('contact_placeholder_company')" required="required" v-model="company" />
            <div class="help-block with-errors"></div>
          </div>
        </div>
        <div class="contact-form-controls-row">
          <div class="form-group">
            <label for="form_email">{{ $t("contact_email") }}</label>
            <input id="form_email" type="email" name="email" class="form-control"
              :placeholder="$t('contact_placeholder_email')" required="required" v-model="email" />
            <div class="help-block with-errors"></div>
          </div>
          <div class="form-group">
            <label for="form_city">{{ $t("contact_city") }}</label>
            <input id="form_city" type="text" name="city" class="form-control"
              :placeholder="$t('contact_placeholder_city')" required="required" v-model="city" />
            <div class="help-block with-errors"></div>
          </div>
        </div>
        <div class="contact-form-controls-row">
          <div class="form-group">
            <label for="form_country">{{ $t("contact_country") }}</label>
            <input id="form_country" type="text" name="country" class="form-control"
              :placeholder="$t('contact_placeholder_country')" required="required" v-model="country" />
            <div class="help-block with-errors"></div>
          </div>
          <div class="form-group"></div>
        </div>
        <div class="contact-form-controls-row">
          <div class="form-group">
            <label for="form_message">{{ $t("contact_message") }}</label>
            <textarea id="form_message" name="message" class="form-control"
              :placeholder="$t('contact_placeholder_message')" rows="4" v-model="message"></textarea>
            <div class="help-block with-errors"></div>
          </div>
        </div>
        <div class="contact-form-controls-row">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" required="required"
              v-model="privacyPolicy" />
            <label class="form-check-label" for="defaultCheck1">
              <a href="https://www.erbe-med.com/fileadmin/pdf/company/Information_On_Data_Protection_EN.pdf"
                target="_blank">
                <i18n-t keypath="contact_policy" scope="global"></i18n-t></a><i18n-t keypath="contact_policy_accepted"
                scope="global"></i18n-t>
            </label>
            <div class="help-block with-errors"></div>
          </div>
        </div>
        <div class="contact-form-controls-row sendingpromptContainer">
          <SendingPrompt promptType="sending" :message="$t('contact_sending')" v-show="sendInProgess" />
          <input v-show="!sendInProgess" type="submit" class="btn btn-success btn-send btn-lg" name="submitButton"
            :value="$t('contact_button')" />
        </div>
        <div class="contact-form-controls-row sendingpromptContainer">
          <SendingPrompt promptType="success" :message="$t('contact_success')" v-show="!sendInProgess && isSuccess" />
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import SendingPrompt from "./SendingPrompt";
import { postContactForm } from "@/services/http/httpService.js";
import { isOnline } from "@/services/http/httpService.js";

export default {
  name: "ContactSection",
  components: {
    SendingPrompt,
  },
  data: function () {
    return {
      name: "",
      company: "",
      email: "",
      city: "",
      country: "",
      message: "",
      privacyPolicy: false,
      sendInProgess: false,
      isSuccess: false,
      isFailure: false,
    };
  },
  methods: {
    processForm: async function () {
      const online = await isOnline();
      if (!online) {
        this.showFailure(this.$i18n.t("contact_failure_connection"));
        return;
      }
      this.sendInProgess = true;
      var result = await postContactForm(
        this.name,
        this.company,
        this.email,
        this.city,
        this.country,
        this.message,
      );
      this.sendInProgess = false;
      const { success } = result;

      if (success) {
        this.showSuccess();
      } else {
        this.showFailure(this.$i18n.t("contact_failure"));
      }
    },

    showSuccess: function () {
      this.isSuccess = true;
      setTimeout(() => {
        this.isSuccess = false;
      }, 7000);
    },
    showFailure: function (message) {
      window.alert(message);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";

.btn {
  cursor: pointer;
}

.contact {
  @include padding-h(60px);
  @include padding-v(32px);

  &-text {
    padding: 32px;
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: center;

    &-headline {
      @include ts-section-headline();
      color: $text_dark;
      margin-bottom: 20px;

      @include breakpoint(s) {
        margin-bottom: 15px;
      }

      @include breakpoint(xs) {
        margin-bottom: 10px;
      }
    }

    &-subheadline {
      @include ts-section-subheadline();
      color: $text_dark;
    }
  }

  &-form {
    color: $text_dark;
    font-family: $font_nettoRegular;
    font-size: 16px;
    margin: 2rem auto 0 auto;
    max-width: 83.333333%;

    @include breakpoint(xs) {
      max-width: 100%;
    }

    @include breakpoint(s) {
      max-width: 100%;
    }

    input[type="submit"] {
      background-color: $color_erbeBlue;
      color: $text_light;
      font-family: $font_nettoRegular;
      padding: 1rem 1.5rem;
      border-radius: 8px;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 30%);
      position: relative;
      top: 0;
      margin: 0 auto;

      &:hover {
        box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 30%);
        top: 2px;
      }
    }

    input[type="text"],
    input[type="email"],
    textarea {
      color: $text_dark;
      font-family: $font_nettoRegular;
      font-size: 16px;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      transition: all 0.3s ease-in-out;
      border: 1px solid #999;
      outline: 0;
      width: 100%;

      &:hover,
      &:focus {
        border: 1px solid $color_erbeBlue;
      }
    }

    textarea {
      max-width: calc(100% - 2rem);
    }

    label {
      display: block;
      margin-bottom: 0.5rem;
    }

    a {
      color: $color_erbeBlue;

      &:hover {
        text-decoration: underline;
      }
    }

    &-controls {
      &-row {
        @include flex-h(space-between, center);
        gap: 2rem;
        margin-bottom: 2rem;

        @include breakpoint(xs) {
          @include flex-v(space-between, center);
        }

        .form-group {
          width: 100%;
          @include flex-v(center, flex-start);
        }

        .form-check {
          @include flex-h(flex-start, center);

          label {
            margin: 0 0 0 0.5rem;
          }
        }
      }
    }
  }
}
</style>