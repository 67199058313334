<template>
  <div class="video" :class="{ 'video--full': isFullScreen }">
    <VideoOffline v-if="useOfflineVideo === true" :vimeoId="vimeoId" :isFullscreen="isFullScreen" :autoplay="autoplay" />
    <VideoOnline v-if="useOfflineVideo === false" :vimeoId="vimeoId" :isFullscreen="isFullScreen" :autoplay="autoplay" />
  </div>
</template>

<script>
import VideoOnline from "./VideoOnline";
import VideoOffline from "./VideoOffline";
import { isVideoAvailable } from "@/services/caching/cachingHelpers";
export default {
  name: "VideoComponent",
  data() {
    return {
      useOfflineVideo: undefined,
    };
  },
  components: {
    VideoOnline,
    VideoOffline,
  },
  props: {
    vimeoId: String,
    autoplay: {
      default: false,
      type: Boolean,
    },
    isFullScreen: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    isPwaModeActive() {
      return this.$store.state.isPwaModeActive;
    },
    serviceWorkerReady() {
      return this.$store.state.serviceWorkerReady;
    },
  },
  watch: {
    serviceWorkerReady() {
      this.setupVideo();
    },
  },
  mounted() {
    this.setupVideo();
  },
  methods: {
    async setupVideo() {
      if (this.serviceWorkerReady === undefined) {
        return;
      }
      if (!this.isPwaModeActive || this.serviceWorkerReady === false) {
        this.useOfflineVideo = false;
        return;
      }
      try {
        if (this.serviceWorkerReady) {
          if (!this.$store.state.failedVimeoVideos.includes(this.vimeoId)) {
            const isAvailable = await isVideoAvailable(this.vimeoId);
            if (!isAvailable) {
              this.$store.commit("addFailedVimeoVideo", this.vimeoId);
            }
            this.useOfflineVideo = isAvailable;
          }
        }
      } catch (e) {
        //eslint-disable-next-line
        console.log(e);
      } finally {
        if (this.useOfflineVideo === undefined) {
          this.useOfflineVideo = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";

.video {
  position: relative;

  &--full {
    height: 56.25vw;
    max-height: 95%;
  }
}
</style>
