export const SchemaSyncHandler = {
  sync() {
    const {
      idb,
      cacheHelper
    } = self;
    if (idb && cacheHelper) {
      cacheHelper.openDatabase();
    }
  },
};
