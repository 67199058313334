import { createI18n } from 'vue-i18n'
import de from "./de.json";
import en from "./en.json";

export const i18n = createI18n({
  locale: window.location.hash.split("/")[1],
  fallbackLcale: "en",
  allowComposition: false,
  messages: {
    de,
    en,
  },
});
