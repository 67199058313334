import { createApp } from "vue";
import VueAgile from "vue-agile";
import App from "./App.vue";
import "./registerServiceWorker";
import { router } from "./router";
import {
  setLanguageParameter
} from "./router/router.extensions";
import { store } from "./store";
import { i18n } from './locales/i18n'
import {
  SchemaSyncHandler
} from "@/services/caching/schemasync";

import {
  initializeAnalytics
} from "@/services/analytics";

setLanguageParameter(router);

SchemaSyncHandler.sync();

initializeAnalytics();

const app = createApp(App);
app.use(store);
app.use(router)
app.use(i18n)
app.use(VueAgile)
app.mount("#app");