<template>
  <a :href="href" target="_blank">
    <slot></slot>
  </a>
</template>

<script>
import {
  createPdfUrl,
  isPdfAvailable,
} from "@/services/caching/cachingHelpers";
export default {
  name: "PdfDocument",
  props: {
    name: String,
  },
  data() {
    return {
      href: "",
    };
  },
  mounted() {
    this.setHref();
  },
  watch: {
    "$i18n.locale"() {
      this.setHref();
    },
    serviceWorkerReady() {
      this.setHref();
    },
  },
  computed: {
    isPwaModeActive() {
      return this.$store.state.isPwaModeActive;
    },
    serviceWorkerReady() {
      return this.$store.state.serviceWorkerReady;
    },
  },
  methods: {
    async setHref() {
      const url = this.$store.getters[this.name];
      if (this.isPwaModeActive && this.serviceWorkerReady === true) {
        const fullKey = this.name + "_" + this.$store.state.language;
        const isAvailable = await isPdfAvailable(fullKey);
        if (isAvailable) {
          this.href = createPdfUrl({
            key: fullKey,
            url,
          });
          return;
        }
      }
      this.href = url;
    },
  },
};
</script>
