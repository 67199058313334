export const createVideosModule = () => {
  return {
    state: {},
    getters: {
      allVideos: () => (
        [
          '352898182',
          '605506909',
          '605506802',
          '440251946',
          '440014204',
          '312685773',
          '440014204',
          '355305179',
          '690961577',
          '696924990',
          '696925135',
          '696925231',
          '605506909',
          '655291545',
          '605506367',
          '354817258',
          '244346791',
          '325442598',
          '712880119'
        ]
      ),
    }
  }
}
