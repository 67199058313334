import { createRouter, createWebHashHistory } from 'vue-router'
import Home from "../views/Home";


const routes = [
  {
    path: "/:lang",
    name: "home",
    component: Home,
    children: [{ path: ":page", component: Home, }],
  },
];

function scrollBehavior(to) {
  if (to.params.page) {
    return { selector: `#${to.params.page}` };
  }
}

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior,
  duplicateNavigationPolicy: "reload",
});

