<template>
  <div id="cache-control" />
</template>

<script>
import { startWorker } from "@/registerServiceWorker";
import { checkAll } from "@/services/caching/cachingHelpers";

export default {
  name: "CacheControl",
  mounted() {
    startWorker(this.serviceWorkerResponse);
  },
  methods: {
    serviceWorkerResponse({ type }) {
      if (type === "ready") {
        this.$store.commit("serviceWorkerReady", true);
        checkAll();
      }
      if (type === "updatefound") {
        // todo: notify the user
      }
      if (type === "error") {
        this.$store.commit("serviceWorkerReady", false);
      }
    },
  },
};
</script>
