<template>
  <div class="settings-main" v-if="hasBackgroundCheckFinished">
    <h2 class="settings-main__headline">{{ $t("install_guide") }}</h2>
    <p class="settings-main__subheadline">
      {{ $t(`install_guide_for_offline_usage_headline`) }}
    </p>
    <p class="settings-main__paragraph">
      {{ $t("install_guide_download_instructions") }}
    </p>
    <DownloadProgress />
    <div class="settings-main__installguide">
      <p class="settings-main__subheadline">
        {{ $t(`install_guide_headline`) }}
      </p>
      <div class="settings-main__installguide-item" v-for="browser in browserInstallData" :key="browser.headline">
        <p class="settings-main__installguide-item-headline">
          {{ $t(browser.headline) }}
        </p>
        <p v-for="count in browser.steps" :key="count">
          {{ $t(browser.installStepTitle + count) }}
        </p>
      </div>
    </div>
    <button v-if="!isDownloading" class="settings-main__button settings-main__button--right" @click="closeModal">
      <p>{{ $t("close") }}</p>
    </button>
  </div>
  <div v-else>
    <p class="settings-main__subheadline" style="margin: 12px; text-align: center;">
      {{ $t(`install_guide_serviceworker_warming_up`) }}
    </p>
  </div>
</template>

<script>
import DownloadProgress from "./DownloadProgress";
export default {
  name: "SettingsMain",
  components: {
    DownloadProgress,
  },
  data() {
    return {
      browserInstallData: [
        {
          headline: "install_guide_headline_safari",
          steps: 3,
          installStepTitle: "install_steps_safari",
        },
        {
          headline: "install_guide_headline_chrome",
          steps: 2,
          installStepTitle: "install_steps_chrome",
        },
        {
          headline: "install_guide_headline_firefox",
          steps: 2,
          installStepTitle: "install_steps_firefox",
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("setIsSettingsModalVisible", false);
    },
  },
  computed: {
    hasBackgroundCheckFinished() {
      return this.$store.state.hasBackgroundCheckFinished;
    },
    isDownloading() {
      return this.$store.state.isDownloading;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/";

.settings-main {
  padding: 30px;
  flex: 1;
  @include flex-v(flex-start, flex-start);
  font-family: $font_nettoRegular;
  position: relative;

  &__headline {
    color: limegreen;
    margin-bottom: 12px;
    font-family: $font_nettoBold;
  }

  &__subheadline {
    font-family: $font_nettoBold;
    margin-bottom: 12px;
  }

  &__paragraph {
    margin-bottom: 5px;
  }

  &__installguide {
    margin-top: 12px;

    &-item {
      margin-bottom: 12px;

      &-headline {
        font-family: $font_nettoBold;
      }
    }
  }

  &__button {
    color: $color_erbeBlue;
    font-family: $font_nettoRegular;
    text-transform: uppercase;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
}
</style>
