<template>
  <div class="navigation">
    <nav id="navigation-main" class="navigation-main" ref="main">
      <router-link :to="rootPath">
        <img class="navigation-main-logo" src="@/assets/images/Erbe_logo.png" alt="Erbe company logo"
          v-on:click="scrollToElement('introduction')" />
      </router-link>
      <div class="global_flex1"></div>
      <button class="navigation-main-toggle" v-on:click="toggleMenu()">
        <img :src="menuIconSrc" />
      </button>
      <ul :class="`navigation-main-items navigation-main-items--${isMenuVisible ? 'open' : 'closed'
        }`">
        <li v-for="item in navItems" :key="item.key" v-on:click="scrollToElement(item.scrollTo)">
          <router-link class="navigation-main-item" :to="item.to">
            {{ $i18n.t(item.key) }}
          </router-link>
        </li>
        <li v-if="isSettingsButtonVisible" v-on:click="toggleDownloadMenu()">
          <button class="navigation-main-item">
            {{ $i18n.t("navigation_item_settings") }}
          </button>
        </li>
        <!-- <li>
          <router-link
            href="#"
            class="navigation-main-item"
            :to="languageSwitch.to"
            :title="$i18n.t('navigation_langswitch_title')"
            @click.native="scrollToElement(null)"
          >
            de/en
          </router-link>
        </li> -->
      </ul>
    </nav>
  </div>
</template>

<script>
import { scrollToElementById } from "@/services/scroll.js"
function setItems(prefix) {
  return [
    {
      to: prefix,
      scrollTo: "introduction",
      key: "navigation_item_introduction",
    },
    {
      to: prefix + "solutions",
      scrollTo: "accordion",
      key: "navigation_item_solutions",
    },
    {
      to: prefix + "workstation",
      scrollTo: "workstation",
      key: "navigation_item_workstation",
    },
    {
      to: prefix + "contact",
      scrollTo: "contact",
      key: "navigation_item_contact",
    },
    {
      to: prefix + "multimedia",
      scrollTo: "multimedia",
      key: "navigation_item_multimedia",
    },
  ];
}
export default {
  data() {
    return {
      isMenuVisible: false,
      navItems: [],
    };
  },
  mounted() {
    this.navItems = setItems(this.rootPath);
  },
  watch: {
    "$i18n.locale"() {
      this.navItems = setItems(this.rootPath);
    },
    isMenuVisible() {
      document.body.style.overflow = this.isMenuVisible ? "hidden" : "visible";
    },
  },
  computed: {
    rootPath() {
      return `/${this.$store.state.language}/`;
    },
    languageSwitch() {
      const to = this.$store.state.language === "de" ? "en" : "de";
      return {
        to: "/" + to,
      };
    },
    menuIconSrc() {
      return this.isMenuVisible
        ? "/svg/nav_menu_open.svg"
        : "/svg/nav_menu_closed.svg";
    },
    isSettingsButtonVisible() {
      return this.$store.state.serviceWorkerReady;
    },
  },
  methods: {
    scrollToElement(id) {
      this.isMenuVisible = false;
      scrollToElementById(id);
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    toggleDownloadMenu() {
      this.$store.commit("setIsSettingsModalVisible", true);
      if (this.isMenuVisible) {
        this.isMenuVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/";

.navigation {
  @include flex-v(stretch, stretch);

  &-main {
    height: $navBarHeight;

    @include breakpoint(xs) {
      height: $navBarHeightXS;
    }

    @include breakpoint(s) {
      height: $navBarHeightS;
    }
  }

  &-main {
    background: black;
    @include flex-h(space-between, center);
    @include padding-h(60px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    @include breakpoint(xs) {
      @include padding-h(30px);
    }

    @include breakpoint(s) {
      @include padding-h(30px);
    }

    &-logo {
      height: 70px;
      display: block;

      @include breakpoint(xs) {
        height: 30px;
      }

      @include breakpoint(s) {
        height: 50px;
      }
    }

    &-items {
      @include flex-h(center, center);

      @mixin itemsStyle {
        @include flex-v(center, center);
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.78);
        text-align: center;

        &--open {
          display: block;
        }

        &--closed {
          display: none;
        }
      }

      @include breakpoint(xs) {
        @include itemsStyle();
        top: $navBarHeightXS;
      }

      @include breakpoint(s) {
        @include itemsStyle();
        top: $navBarHeightS;
      }
    }

    &-item {
      font-family: $font_nettoBold;
      @include margin-h(25px);
      @include text(20px, 24px, 0px);
      color: #00aeef;
      display: block;

      @include breakpoint(xs) {
        @include margin-h(0);
        @include text(18px, 28px, 0px);
        margin-top: 30px;
        width: 100%;
      }

      @include breakpoint(s) {
        @include margin-h(0);
        @include text(24px, 30px, 0px);
        margin-top: 30px;
        width: 100%;
      }
    }

    &-toggle {
      display: none;

      @mixin toggleStyle {
        @include flex-h(center, center);
        padding: 8px;
        margin: -8px;
      }

      @include breakpoint(xs) {
        @include toggleStyle();
      }

      @include breakpoint(s) {
        @include toggleStyle();
      }
    }
  }
}
</style>
