<template>
  <a class="pointer-link" v-bind:class="alignment" :style="[
    alignment == 'left'
      ? { left: x + '%', top: y + '%' }
      : { right: x + '%', top: y + '%' },
  ]">
    <span class="pointer-link-dot">
      <img class="pointer-link-dot-svg" :src="dot" />
    </span>
    <span class="pointer-link-text">
      <i18n-t :keypath="name" scope="global"></i18n-t>
    </span>
  </a>
</template>

<script>
export default {
  name: "PointerLink",
  props: {
    name: String,
    alignment: String,
    x: String,
    y: String,
  },
  data() {
    return {
      left: true,
      right: false,
    };
  },
  computed: {
    dot() {
      return require("@/assets/svg/pointer-dot.svg");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.pointer-link {
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 50%;

  &.left {
    flex-direction: row;
    text-align: left;
  }

  &.right {
    flex-direction: row-reverse;
    text-align: right;
  }

  &-dot {
    align-self: center;
    display: block;
    height: 60px;
    min-height: 60px;
    position: relative;
    transition: all 0.3s ease-in-out;
    width: 60px;
    min-width: 60px;

    @include breakpoint(s) {
      height: 50px;
      width: 50px;
      min-height: 50px;
      min-width: 50px;
    }

    @include breakpoint(xs) {
      height: 32px;
      width: 32px;
      min-height: 32px;
      min-width: 32px;
    }

    img {
      height: auto;
      left: 50%;
      margin: 0 auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
    }
  }

  &-text {
    align-self: center;
    display: block;
    font-family: $font_nettoBold;
    font-size: 20px;
    margin-left: 8px;
    transition: all 0.3s ease-in-out;
    max-width: 290px;

    @include breakpoint(s) {
      font-size: 18px;
      max-width: 150px;
    }

    @include breakpoint(xs) {
      font-size: 14px;
      max-width: 110px;
    }
  }

  &:hover {
    .pointer-link-dot {
      transform: scale(70%, 70%)
    }
  }
}
</style>