import {
  languageAdapter,
} from "@/services/languageAdapter";

export function setLanguageParameter(router) {
  router.beforeEach((to, _from, next) => {
    if (to.params.page) {
      next();
    } else if (to.params && to.params.lang && languageAdapter.isSupported(to.params.lang)) {
      if (to.path.slice(-1) !== "/") {
        next(`/${to.params.lang}/`);
      } else {
        next();
      }
    } else {
      next(`/${languageAdapter.getLanguageOfUser()}/`);
    }
  });
}