<template>
  <div id="modal-settings" class="modal-settings" @click.stop="closeModal">
    <div class="modal-settings__inner">
      <Settings />
    </div>
  </div>
</template>

<script>
import Settings from "./SettingsMain";
export default {
  name: "SettingsModal",
  components: {
    Settings,
  },
  mounted() {
    setTimeout(() => {
      document.body.style.overflow = "hidden";
    }, 0);
  },
  beforeUnmount() {
    document.body.style.overflow = "visible";
  },
  computed: {
    modalWidth() {
      if (window.innerWidth <= 575) {
        return "100%";
      } else {
        return "70%";
      }
    },
  },
  methods: {
    closeModal(e) {
      if (
        !this.$store.state.isDownloading &&
        e.target.id === "modal-settings"
      ) {
        this.$store.commit("setIsSettingsModalVisible", false);
      }
    },
    beforeClose(event) {
      if (this.isDownloading) {
        event.stop();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/";

.modal-settings {
  @include modal();
  @include flex-v(center, center);

  &__inner {
    background: white;
    overflow: scroll;
    @include flex-v(flex-start, stretch);
    width: 90%;
    margin: 5%;
    max-height: 90%;

    @include breakpoint(xs) {
      margin: 0;
      max-height: 100vh;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
