<template>
    <div class="listWithArrows">
      <ul>
        <li v-for="entry in entriesLeft" :key="entry">
          <img class="listWithArrows-arrow" :src="image" />
          <div :style="{ color: color }" class="listWithArrows-text">
            {{ $t(entry) }}
          </div>
        </li>
      </ul>
      <ul>
        <li v-for="entry in entriesRight" :key="entry">
          <img class="listWithArrows-arrow" :src="image" />
          <div :style="{ color: color }" class="listWithArrows-text">
            {{ $t(entry) }}
          </div>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: "ListWithArrows",
  props: {
    entriesLeft: Array,
    entriesRight: Array,
    color: {
      default: "#231F20",
      type: String,
    },
    flavor: {
      default: "black",
      type: String,
    },
  },
  computed: {
    image() {
      return require("@/assets/svg/icon-arrow-right@1x.svg");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.listWithArrows {
  margin-bottom: 50px;
  gap: 50px;
  @include flex-h(space-evenly, flex-start);

  @include breakpoint(xs) {
    @include flex-v(center center);
    @include padding-h(10px);
  }
  @include breakpoint(s) {
    @include margin-h(10%);
  }

  ul {
    @include ie_or_edge() {
      flex: inherit;
      width: 100%;
    }
  }
  li {
    padding-bottom: 10px;
    @include flex-h(flex-start, flex-start);
    @include ie_or_edge() {
      width: 100%;
    }
  }

  &-arrow {
    height: 24px;
    width: 24px;
    
    @include breakpoint(xs) {
      margin-top: 0px;
    }
    @include breakpoint(s) {
      margin-top: 0px; 
    }
  }

  &-text {
    text-align: left;
    line-height: 25px;
    padding-left: 15px;
    max-width: 400px;
    font-size: 16px;
  }
}
</style>
