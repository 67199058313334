export const createAccordionSectionCollapseModule = () => {
  return {
    state: {
      obstruction: false,
      pulmonary: false,
      interstitial: false,
      peripheral: false,
      aspiration: false,
      benign: false,
      exsudative: false,
      openedId: undefined,
    },
    getters: {
      getCollapseStateOfId: state => sectionId => {
        for (const collapseStateKey in state) {
          if (collapseStateKey.toLowerCase().includes(sectionId)) {
            return state[collapseStateKey];
          }
        }
      },
      getOpenedId: state => state.openedId,
    },
    mutations: {
      setCollapseStateToTrue(state, sectionId) {
        for (const collapseStateKey in state) {
          if (collapseStateKey.toLowerCase().includes(sectionId)) {
            state[collapseStateKey] = !state[collapseStateKey];
          } else {
            if (collapseStateKey !== "openedId") {
              state[collapseStateKey] = false;
            }
          }
        }
        state.openedId = state.openedId === sectionId ? undefined : sectionId;
      },
    },
  };
};
