<template>
  <section id="multimedia" class="multimedia">
    <img class="multimedia-image" :src="styleImgMultimedia" />
    <div class="multimedia-text">
      <h2 class="multimedia-text-headline">
        <i18n-t keypath="multimedia_header" scope="global"></i18n-t>
      </h2>
    </div>
    <div class="multimedia-grid">
      <multimedia-grid-item v-for="item in multimediaItems" :key="item.key" :id="item.id" :headline="item.headline"
        :subheadline="item.subheadline" :src="item.icon" :href="item.href" :backgroundColor="item.bgColor">
      </multimedia-grid-item>
    </div>
  </section>
</template>

<script>
import MultimediaGridItem from "@/components/MultimediaGridItem.vue";
export default {
  name: "MultimediaSection",
  components: {
    MultimediaGridItem,
  },
  props: {
    backgroundColor: String,
  },
  data() {
    return {
      multimediaItems: [
        {
          headline: "multimedia_grid_item_headline1",
          subheadline: "multimedia_grid_item_subheadline1",
          icon: "/svg/multimedia-icon-erbe.svg",
          href: "https://de.erbe-med.com/de-en/medical-specialties/pulmonology/",
          bgColor: "#8bc751",
        },
        {
          headline: "multimedia_grid_item_headline2",
          subheadline: "multimedia_grid_item_subheadline2",
          icon: "/svg/multimedia-icon-publication.svg",
          href: "https://de.erbe-med.com/de-en/education/publications/",
          bgColor: "#00ae9d",
        },
        {
          headline: "multimedia_grid_item_headline3",
          subheadline: "multimedia_grid_item_subheadline3",
          icon: "/svg/multimedia-icon-podcast.svg",
          href: "https://podcastb0e4aa.podigee.io/",
          bgColor: "#00ac6c",
        },
        {
          headline: "multimedia_grid_item_headline4",
          subheadline: "multimedia_grid_item_subheadline4",
          icon: "/svg/multimedia-icon-learning.svg",
          href: "https://www.academy.erbe-med.com/",
          bgColor: "#00afad",
        },
        {
          headline: "multimedia_grid_item_headline5",
          subheadline: "multimedia_grid_item_subheadline5",
          icon: "/svg/multimedia-icon-video.svg",
          href: "https://www.medical-videos.com/category/applications/pulmonology/",
          bgColor: "#bed747",
        }
      ],
    };
  },
  computed: {
    styleImgMultimedia() {
      return this.$store.getters.img_multimedia;
    },
    bgColor() {
      return {
        "background-color": this.backgroundColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.multimedia {
  height: auto;
  position: relative;
  overflow: hidden;

  &-image {
    height: auto;
    width: 100%;
    min-width: 1920px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint(xs) {
      min-width: 1440px;
      top: 50%;
    }

    @include breakpoint(s) {
      min-width: 1440px;
      top: 50%;
    }
  }

  &-text {
    @include padding-h(60px);
    @include padding-v(60px);
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: center;

    &-headline {
      @include ts-section-headline();
      color: $text_dark;
      margin-bottom: 20px;
      text-shadow: 0px 0px 3px #FFFFFF;

      @include breakpoint(s) {
        margin-bottom: 15px;
      }

      @include breakpoint(xs) {
        margin-bottom: 10px;
      }
    }

    &-subheadline {
      @include ts-section-subheadline();
      color: $text_dark;
    }
  }

  &-grid {
    @include padding-h(60px);
    margin-bottom: 4rem;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;

    @include breakpoint(s) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(xs) {
      grid-template-columns: 1fr;
    }
  }
}
</style>