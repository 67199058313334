const documents = {
  en: {
    accordion_pulmonary_scientific_card_1: "https://de.erbe-med.com/erbe/media/Marketingmaterialien/85200-104_ERBE_EN_Cryoextractions_of_blood_clots__D195888.pdf",
    accordion_interstitial_scientific_card_1: "https://de.erbe- med.com/fileadmin/images/Fortbildung/_Neu__Publikationen/Erbe_EN_2020_Transbronchial_lung_cryobiopsy_for_diagnosis_of_interstitial_l ung_diseases.pdf",
    accordion_interstitial_scientific_card_2: "https://de.erbe-med.com/productfinder/Marketingmaterialien/85200-105_ERBE_EN_Transbronchial_cryobiopsy,_ILD__D198417.pdf",
    accordion_interstitial_scientific_card_3: "https://de.erbe-med.com/productfinder/Marketingmaterialien/_ERBE_EN_Transbronchial_cryobiopsy,_ILD__D207438.pdf",
    accordion_pulmonary_lesions_scientific_card_1: "https://de.erbe-med.com/fileadmin/images/Fortbildung/_Neu__Publikationen/85200-X03_Peripheral-cryobiopsy-nodules_2021-02.pdf",
    accordion_pulmonary_lesions_scientific_card_2: "https://de.erbe-med.com/productfinder/Marketingmaterialien/_ERBE_EN_EBUS-TBINCB__D224801.pdf"
  },
  de: {
    accordion_pulmonary_scientific_card_1: "https://de.erbe-med.com/erbe/media/Marketingmaterialien/85200-104_ERBE_EN_Cryoextractions_of_blood_clots__D195888.pdf",
    accordion_interstitial_scientific_card_1: "https://de.erbe- med.com/fileadmin/images/Fortbildung/_Neu__Publikationen/Erbe_EN_2020_Transbronchial_lung_cryobiopsy_for_diagnosis_of_interstitial_l ung_diseases.pdf",
    accordion_interstitial_scientific_card_2: "https://de.erbe-med.com/productfinder/Marketingmaterialien/85200-105_ERBE_EN_Transbronchial_cryobiopsy,_ILD__D198417.pdf",
    accordion_interstitial_scientific_card_3: "https://de.erbe-med.com/productfinder/Marketingmaterialien/_ERBE_EN_Transbronchial_cryobiopsy,_ILD__D207438.pdf",
    accordion_pulmonary_lesions_scientific_card_1: "https://de.erbe-med.com/fileadmin/images/Fortbildung/_Neu__Publikationen/85200-X03_Peripheral-cryobiopsy-nodules_2021-02.pdf",
    accordion_pulmonary_lesions_scientific_card_2: "https://de.erbe-med.com/productfinder/Marketingmaterialien/_ERBE_EN_EBUS-TBINCB__D224801.pdf"
  }
}

export const createDocumentsModule = () => {
  return {
    state: {},
    getters: {
      allDocuments: () => {
        const de = Object.entries(documents.de).map(([key, url]) => ({ key: key + "_" + "de", url }))
        const en = Object.entries(documents.en).map(([key, url]) => ({ key: key + "_" + "en", url }))
        return de.concat(en)
      },

      accordion_pulmonary_scientific_card_1: (state, getters, rootState) => {
        return documents[rootState.language]["accordion_pulmonary_scientific_card_1"];
      },

      accordion_interstitial_scientific_card_1: (state, getters, rootState) => {
        return documents[rootState.language]["accordion_interstitial_scientific_card_1"];
      },

      accordion_interstitial_scientific_card_2: (state, getters, rootState) => {
        return documents[rootState.language]["accordion_interstitial_scientific_card_2"];
      },

      accordion_interstitial_scientific_card_3: (state, getters, rootState) => {
        return documents[rootState.language]["accordion_interstitial_scientific_card_3"];
      },

      accordion_pulmonary_lesions_scientific_card_1: (state, getters, rootState) => {
        return documents[rootState.language]["accordion_pulmonary_lesions_scientific_card_1"];
      },

      accordion_pulmonary_lesions_scientific_card_2: (state, getters, rootState) => {
        return documents[rootState.language]["accordion_pulmonary_lesions_scientific_card_2"];
      }


    },
  };
};
