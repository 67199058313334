<template>
  <div class="outer-container background-image">
    <span class="title">{{
      $t("accordion_products_intervention_headline")
    }}</span>
    <div class="inner-container">
      <ProductsForInterventionColumn
        :fullSize="!rightItems"
        :items="leftItems"
        :information="leftInformation"
        :buttonLink="leftButtonLink"
      />
      <ProductsForInterventionColumn
        v-if="rightItems"
        :fullSize="false"
        :items="rightItems"
        :information="rightInformation"
        :buttonLink="rightButtonLink"
      />
    </div>
  </div>
</template>

<script>
import ProductsForInterventionColumn from "./ProductsForInterventionColumn.vue";
export default {
  name: "ProductsForIntervention",
  components: { ProductsForInterventionColumn },
  props: {
    leftItems: Array,
    rightItems: Array,
    leftInformation: Array,
    rightInformation: Array,
    leftButtonLink: String,
    rightButtonLink: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";
.title {
  font-size: 32px;
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 90%;
}
.outer-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inner-container {
  display: flex;
  flex-direction: row;
  gap: 10%;
  max-width: 90%;
  padding-bottom: 2rem;

  @include breakpoint(s) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @include breakpoint(xs) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
.background-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("../assets/images/Flexible_cryoprobe_SU_20402-401_3D_Cover_sw-V2-font_1920x2193.jpg");
  transition: all 0.3s ease-in-out;
}
</style>
