const supportedLanguages = ["de", "en"];

const getLanguageOfUserFromPath = params => {
  return params && params.lang ? params.lang : getLanguageOfUser();
};

const isSupported = (language) => {
  return supportedLanguages.includes(language)
}

const getLanguageOfUser = () => {
  const languageFromNavigator = self.navigator.browserLanguage || self.navigator.language;
  const languageCode = languageFromNavigator.toString().slice(0, 2);
  if (isSupported(languageCode)) {
    return languageCode;
  }
  return "en";
};

export const languageAdapter = {
  getLanguageOfUserFromPath,
  getLanguageOfUser,
  isSupported,
};